const commentsData = [
  {
    id: 1,
    rating: 5,
    comment: "Allbuilt Homes has the ability to handle large projects and small projects and treats all projects the same. Vitalii and his team have delivered high-quality results and always handle challenges as they come up on custom projects. I highly recommend Allbuilt to deliver quality at a fair price.",
    name: 'Jordan Davis'
  },
  {
    id: 2,
    rating: 5,
    comment: "Outstanding job remodeling my house! I appreciate your thorough communication and the high quality of your work. I will definitely recommend you to my clients, friends, and family. Thank you very much!",
    name: "Lora Pindel"
  },
  {
    id: 3,
    rating: 5,
    comment: "Incredible job on the siding! The transformation of my home is remarkable. Your dedication and skill have exceeded all expectations.",
    name: "Jessica Thompson"
  },
  {
    id: 4,
    rating: 5,
    comment: "Exquisite interior finish work! The elegance and quality are unmatched. Thank you for turning my vision into a reality with such finesse.",
    name: "Ryan Miller"
  },
  {
    id: 5,
    rating: 4,
    comment: "Outstanding siding work! The results speak volumes about your professionalism and expertise. My home has never looked better. Thank you!",
    name: "Olivia Anderson"
  }
];

export default commentsData;
